<template>
  <div>
    <!--탑메뉴-->
    <topbar-comp></topbar-comp>
    <div id="contents">
      <sub-title><i class="fa fa-tags" aria-hidden="true"></i>그래프</sub-title>
      <div class="board_list">
        <iframe id="bustaUrl" :src="bustabitUrl"
                style="width: 100%;height:700px" scrolling="no" frameborder="0"></iframe>
      </div>
    </div>
    <right-bar-comp>
      <right-bar-banner-comp slot="rightBanner"></right-bar-banner-comp>
    </right-bar-comp>
    <left-bar-comp>
      <leisure-left-comp></leisure-left-comp>
    </left-bar-comp>
    <!--푸트메뉴-->
    <foot-comp></foot-comp>
  </div>
</template>

<script>
  import TopbarComp from "../../components/TopbarComp";
  import RightBarComp from "../../components/RightBarComp";
  import RightBarBannerComp from "../../components/RightBarBannerComp";
  import LeftBarComp from "../../components/LeftBarComp";
  import LeisureLeftComp from "../../components/leisuregame/LeisureLeftComp";
  import FootComp from "../../components/FootComp";
  import SubTitle from "../../components/SubTitle";
  import {totoCash2BustabitCash} from "../../network/bustabitRequest";
  export default {
    name: "Bustabit",
    components: {SubTitle, FootComp, LeisureLeftComp, LeftBarComp, RightBarBannerComp, RightBarComp, TopbarComp},
    data(){
      return {
        bustabitUrl: ''
      }
    },
    created() {
      totoCash2BustabitCash().then(res=>{
        this.$store.dispatch('actionUserInfo')
        if(res.data.success){
          this.bustabitUrl = res.data.data;
        } else {
          this.$swal({
            title: '그래프 오류입니다.관리자에 연락주세요',
            type: 'error',
            showCancelButton: false,
            showConfirmButton: true
          })
        }
      })
    },
    mounted() {
    }
  }
</script>

<style scoped>

</style>